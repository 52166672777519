.signinPageContainer {
  padding-left: 0 !important;
  padding-right: 0 !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  text-align: center;
}

.signinPageContainer.default {
  background-image: url('/public/assets/img/bgImg.jpg');
}

.signinPageContainer.investment {
  background-image: url('/public/assets/img/investmentBg.png');
}

.signinPageContainer.veterinary {
  background-image: url('/public/assets/img/Veterinary.jpg');
}

.signinPageContainer.grower {
  background-image: url('/public/assets/img/fpo.jpg');
}

.signinPageContainer.buyer {
  background-image: url('/public/assets/img/buyer.jpg');
}

.signinPageContainer.reseller {
  background-image: url('/public/assets/img/seller.jpg');
}

.signinPageContainer.agent {
  background-image: url('/public/assets/img/Agent2.png');
}

.signinPageContainer.merchant {
  background-image: url('/public/assets/img/seller_2.jpg');
}

.signupOuterContainer {
  margin: 25px auto 25px !important;
}

.loginContainer {
  min-height: 350px;
  background-color: #fafafa;
  box-shadow: 2px 5px 25px #888888;
  border-radius: 5px;
  text-align: center;
  padding: 25px 45px 10px 45px;
  max-width: 350px !important;
}

.loginFields {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.loginBtn {
  margin-top: 5px !important;
  color: #fff !important;
}

.rememberMe {
  float: left;
  text-align: left;
  font-size: 12px !important;
  color: #969798 !important;
  margin-top: 15px;
  letter-spacing: 0.5px;
}

.rememberMe span:nth-child(1) {
  color: #68696d !important;
}

.rememberMe input[type='checkbox']:checked {
  color: #f3bd48 !important;
}

.rememberMe span input[type='checkbox'] {
  border: 2px solid #68696d !important;
  background-color: #68696d;
}

.rememberMe span:nth-child(2) {
  font-size: 12px !important;
  color: #68696d !important;
}

.forgotPwdLink {
  font-size: 12px;
  color: #cbcdcd;
  margin-top: 12px !important;
  /*margin-bottom: 10px !important;*/
  letter-spacing: 0.5px;
  cursor: pointer;
}

.forgotPwdLink:hover {
  color: #68696d;
}
.anchorTagStyle {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54) !important;
}
.modalWrapper {
  text-align: center;
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
  overflow-x: hidden;
}

.customModalStyle {
  position: relative !important; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: calc(70% - 500px) !important;
  margin-top: 100px;
  margin-bottom: 100px;
  width: 500px; /* Full width */
  max-height: 100vh !important; /* Full height */
  overflow-y: scroll; /* Enable scroll if needed */
}
.closeButton {
  background: '#FFF';
  font-size: '23px';
  float: 'right';
  width: 5%;
  padding-top: 15px;
}
.closeButton:hover {
  cursor: pointer !important;
}
.headerStyle {
  display: inline;
}
.headerStyle h3 {
  float: left;
  width: 95%;
}
.updateProfileContainer {
  min-height: 300px;
  background-color: #fafafa;
  box-shadow: 2px 5px 25px #888888;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
}
